import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link } from "gatsby"
import React from "react"
import ImageGallery from "react-image-gallery"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"

function GalleryTemplate({ pageContext }) {
  const baseUrl =
    typeof process.env.IMAGE_BASE_URL !== "undefined"
      ? process.env.IMAGE_BASE_URL
      : ""

  const images = pageContext.images.map(img => ({
    originalAlt: pageContext.title,
    originalTitle: pageContext.title,
    original: `${baseUrl}${img.url}`,
    thumbnail: `${baseUrl}${img.formats.thumbnail.url}`,
  }))

  const galleryLink =
    pageContext.menuType === "primary"
      ? "/szkola-podstawowa/galeria"
      : "/przedszkole/galeria"

  return (
    <Layout menuType={pageContext.menuType}>
      <SEO title={"Galeria / " + pageContext.title + " | ZSP Nidek"} />
      <Link
        to={galleryLink}
        style={{
          textDecoration: "none",
          color: "var(--linkColor)",
        }}
      >
        <FontAwesomeIcon
          icon={faAngleLeft}
          style={{
            fontSize: "1rem",
          }}
        />{" "}
        Wróć do galerii
      </Link>
      <h1>{pageContext.title}</h1>
      <div>
        <ImageGallery items={images} />
      </div>
    </Layout>
  )
}

export default GalleryTemplate
